import React from "react"
import { Box, Heading, Text, Button } from "@chakra-ui/react"
import { navigate } from "gatsby"
import Seo from "../components/seo"
import { COLORS_PRIMARY } from "../components/shared/vomux_colors"

export default function NotFoundPage() {
  React.useEffect(() => {
    navigate("/")
  }, [])

  return (
    <Box textAlign="center" py={10} px={6}>
      <Seo title="404: Not found" />
      <Heading
        display="inline-block"
        as="h2"
        size="2xl"
        bgColor={COLORS_PRIMARY.BACKGROUND_BLUE}
        backgroundClip="text"
      >
        404
      </Heading>
      <Text fontSize="18px" mt={3} mb={2}>
        Page Not Found
      </Text>
      <Text color={"gray.500"} mb={6}>
        The page you're looking for does not seem to exist
      </Text>

      <Button bgColor={COLORS_PRIMARY.DARK_BLUE} color="white" variant="solid">
        Go to Home
      </Button>
    </Box>
  )
}
